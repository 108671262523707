<template>
  <el-card class="box-card" @click="checkCard(item)">
    <template #header>
      <div class="card-header">
        <span class="title"> <strong>{{item.courseName}}</strong> </span>
      </div>
    </template>
    <div class="courseContainer">
      <div class="specific">
        <div class="item">
          <div class="label">教师：</div>
          <div class="props">{{item.createUserName}}</div>
        </div>
        <div class="item">
          <div class="label">学生人数：</div>
          <div class="props">{{item.numberOfPeople}}</div>
        </div>
        <div class="item">
          <div class="label">创建时间：</div>
          <div class="props" style="font-size: 12px">{{item.createTime}}</div>
        </div>
        <div class="item">
          <div class="label">课程时间：</div>
          <div class="props" style="font-size: 12px">{{item.classTime}} ~ {{ item.endTime }}</div>
        </div>
      </div>
      <div class="set">
        <span @click.stop="setCourse(item)" v-if="showButton" style="margin-right: 15px">设置</span>
        <span @click.stop="delCourse(item)" v-if="showButton" style="color: #f56c6c">删除</span>
      </div>
    </div>

  </el-card>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue"
import { useRouter } from "vue-router";
import { checkCards } from './cardMethods'
import { classData } from "./classData";
import { ElMessage } from "element-plus";

export default {
  name: 'course',
  props: {
    item: { type: [Object, Number, String] },
    classStart: { type: Number }
  },
  setup(props, { emit }) {
    let data = reactive(classData)
    let show = reactive({ showButton: false })
    let router = useRouter()
    onMounted(() => {
      if (JSON.parse(sessionStorage.getItem('user')).userRoleName === '教师') {
        show.showButton = true
      }
    })
    const setCourse = (item) => {
      emit('setcourse', item)
    }
    let checkCard = (item) => {
      if (props.classStart !== 1) return ElMessage.warning('课程状态不可进入')
      checkCards(item, router, data);
    }
    let delCourse = () => {}
    return {
      ...toRefs(data),
      ...toRefs(show),
      checkCard,
      setCourse,
      delCourse,
    }
  }
}
</script>

<style lang="less" scoped>
.box-card {
  cursor: pointer;
  height: auto;
  margin-bottom: 20px;
  width: 100%;
  .title {
    display: inline-block;
    text-align: center;
    width: 100%;
    background: #fecd75;
    padding: 18px 20px;
    box-sizing: border-box;
  }
  &:deep(.el-card__header) {
    padding: 0 0;
    border-bottom: #fecd75 !important;
  }
  &:deep(.el-card__body) {
    padding: 0px 0;
  }
  .courseContainer {
    .specific {
      background: #fecd75;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      .item {
        display: flex;
        align-items: center;
        color: #fff;
        padding: 0 40px;
        padding-right: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        .label {
          width: 85px;
          background: rgba(228, 186, 109, 0.8);
          text-align: center;
          margin-right: 10px;
          padding: 5px 0;
          border-radius: 4px;
        }
      }
    }
    .set {
      text-align: right;
      font-size: 14px;
      color: #1890ff;
      background: #fff;
      padding: 10px 20px;
      cursor: pointer;
    }
  }
}
</style>
